.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background: #1b4aa2;
      width: 36px;
      height: 20px;
    }

    &:focus + .slider {
      box-shadow: 0 0 1px #2196f3;
    }

    &:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
      right: 28px;
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #d1d1d1;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    width: 36px;
    height: 20px;
    border-radius: 34px;

    &:before {
      position: absolute;
      content: '';
      right: 18px;
      bottom: 2px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      width: 16px;
      height: 16px;
      box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
        0px 1px 2px rgba(16, 24, 40, 0.06);
      border-radius: 50%;
    }
  }
}
