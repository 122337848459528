.formWrapper {
    padding-top: 1rem;
    .uploadFile {
        label {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;

            color: #344054;
        }

        .documentField {
            margin-top: 6px;
            height: 8rem;
            width: 100%;
            display: flex;
            cursor: pointer;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            border: 1px dashed #D0D5DD;
            background: #FFF;

            .fileUploader {
                display: flex;
                align-items: center;
                cursor: pointer;

                p {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 9.71536px;
                    line-height: 15px;
                    color: #4E7DD5; //--primary--400
                    text-transform: capitalize;
                }
            }
        }
    }
    .formBuild {
        display: flex;
        flex-direction: column;
        gap: 20px;
        flex-wrap: wrap;

        .inputbox {
            display: flex;
            flex-direction: column;
            flex: 1;
            gap: 6px;
            width: 43%;

            label {
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;

                color: #344054;
            }

            input {
                padding: 10px 14px;
                border-radius: 8px;
                border: 1px solid #D0D5DD;
                background: #FFF;
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            }
        }

        .textFields {
            display: flex;
            gap: 20px;
            flex-wrap: wrap;
            flex: 1;

            .message {
                display: flex;
                flex-direction: column;
                gap: 6px;
                flex: 1;
                width: 43%;

                label {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;

                    color: #344054;
                }

                .textField {
                    input {
                        min-height: 12.5rem;
                    }
                }
            }
        }

        .inputUrl {
            display: flex;
            gap: 20px;
            flex-wrap: wrap;

            .inputbox {
                display: flex;
                flex-direction: column;
                gap: 6px;
                width: 43%;

                label {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;

                    color: #344054;
                }

                input {
                    padding: 10px 14px;
                    border-radius: 8px;
                    border: 1px solid #D0D5DD;
                    background: #FFF;
                    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                }
            }
        }

        // address
        .addressWrapper {
            .addressHead {
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;

                color: #344054;
                margin-bottom: 10px;
            }

            .linesAddressWrapper {
                display: flex;
                gap: 20px;

                .linesAddress {
                    display: flex;
                    flex-direction: column;
                    gap: 6px;
                    width: 43%;

                    label {
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 20px;

                        color: #344054;
                    }

                    input {
                        padding: 10px 14px;
                        border-radius: 8px;
                        border: 1px solid #D0D5DD;
                        background: #FFF;
                        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                    }
                }

            }

            .addressWithCodes {
                margin-top: 20px;
                display: flex;
                flex-wrap: wrap;
                gap: 20px;

                .addressState {
                    display: flex;
                    flex-direction: column;
                    gap: 6px;
                    max-width: 43%;

                    label {
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 20px;

                        color: #344054;
                    }

                    input {
                        padding: 10px 14px;
                        border-radius: 8px;
                        border: 1px solid #D0D5DD;
                        background: #FFF;
                        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                    }
                }

                .addressCode {
                    display: flex;
                    flex-direction: column;
                    gap: 6px;

                    label {
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 20px;

                        color: #344054;
                    }

                    input {
                        padding: 10px 14px;
                        border-radius: 8px;
                        border: 1px solid #D0D5DD;
                        background: #FFF;
                        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                    }
                }
            }
        }
    }

    button {
        margin-top: 2rem;
        text-transform: capitalize;
        border-radius: 8px;
        border: 1px solid #1B4AA2;
        background: #1B4AA2;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
}