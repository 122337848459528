@import '../../size.mixin.scss';

.tableWrapper {
  box-sizing: border-box;
  min-width: 100%;
  min-height: 100%;
  background: #ffffff;
  border: 0.5px solid #eaeaea;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.07);
  border-radius: 12px;
  overflow-x: auto;
  .NoData {
    margin: 2rem auto 5rem;
    text-align: center;

    h3 {
      margin: 2rem;
      color: #8391a2;
    }
  }

  table {
    width: calc(100% - 60px);
    border-collapse: collapse;

    th,
    td {
      padding: 10px 20px;
      min-width: fit-content;
    }

    th {
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      color: #8391a2;
      text-align: left;
    }

    td {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #1d2939;

      .reduceData {
        width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      img {
        width: 20.01px;
        height: 18px;
        margin-right: 10px;
      }
    }

    .disputeWrapper {
      border-top: 1px solid #eff0f7;

      .id {
        color: #225ccb; //--primary--500
        font-weight: 500;
        text-decoration: underline;
        cursor: pointer;

        text-overflow: ellipsis;
        overflow: hidden;
      }

      .status {
        display: flex;
        padding: 4px 8px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        width: fit-content;

        p {
          text-transform: lowercase;
        }

        p:first-letter {
          text-transform: capitalize;
        }
      }

      .carrierInfo {
        p {
          color: black;
          text-align: center;
          padding: 4px 8px;
          font-size: 12px;
          border-radius: 4px;
        }
        .deliveredCarrierData {
          background: rgb(188 248 212);
        }
        .inTransiteCarrierData {
          background: rgb(252 228 154);
        }
        .missingCarrierData {
          background: rgb(248 226 224);
          width: 110px;
        }
      }

      .reasonType {
        background: #eff0f7;
        text-align: center;
        border-radius: 4px;
        width: max-content;
        padding: 4px 8px;

        p {
          text-transform: lowercase;
          font-size: 12px;
          font-weight: 500;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        p:first-letter {
          text-transform: capitalize;
        }
      }
    }
  }
}
