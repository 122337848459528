@import '../../../size.mixin.scss';

.HomeWrapper {
  height: 100%;

  .HomeBody {
    width: 100%;
    height: 100%;
    background: #f7f7f7;
    padding: 32px 46px 60px 56px;
    display: flex;
    flex-direction: column;

    @include for-size(xl) {
      padding: 32px 20px 60px;
    }

    @include for-size(lg) {
      padding: 32px 30px;
      gap: 10px;
    }

    @include for-size(xs) {
      padding: 20px;
    }

    .cardsWrapper {
      margin-top: 17px;

      .main {
        .disputesWrapper {
          display: flex;
          justify-content: space-between;
          gap: 17px;
          margin-top: 1rem;

          @include for-size(md) {
            flex-direction: column;
          }

          .card {
            max-width: 100%;
            width: 31%;

            @include for-size(md) {
              width: 100%;
            }
          }
        }

        .orders {
          margin-top: 27px;

          .ordersTable {
            margin-top: 14.5px;
          }
        }

        .recentDisputes {
          margin-top: 48.5px;

          .syncingWrapper {
            margin-top: 12.5px;
            padding: 23px 36.65px 33.4px;
            background: #ffffff;
            border: 0.5px solid #eaeaea;
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.07);
            border-radius: 12px;
            position: relative;

            .syncingSkeleton {
              display: flex;
              justify-content: space-between;
              align-items: flex-start;

              .syncingSkeletonLeft {
                width: 50%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
              }

              .syncingSkeletonRight {
                margin-top: 10px;
                width: 50%;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
              }
            }

            .syncingData {
              text-align: center;
              position: relative;
              top: -56.74px;

              h3 {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                text-align: center;

                color: #344054; //--gray--700
              }

              .syncInfo {
                margin-top: 6.6px;

                p {
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 20px;
                  text-align: center;

                  color: #8391A2; //--gray--500
                }
              }
            }
          }

          .recentDisputesTable {
            margin-top: 29.5px;
          }
        }
      }
    }
  }
}

.card {
  background: #ffffff;
  border: 0.5px solid #eaeaea;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.07);
  border-radius: 12px;
}