@import '../../../../size.mixin.scss';

.targetConditionStyle {
    position: absolute;
    top: 30px;
    left: -13px;
    min-width: 24px;
    min-height: 24px;
    background: #225CCB;
    cursor: pointed !important;
}

.targetTopConditionStyle {
    position: absolute;
    top: -13px !important;
    min-width: 24px !important;
    min-height: 24px !important;
    background: #225CCB !important;
    cursor: pointed !important;
}

.sourceStyle{
    display: inline-block;
    margin-top: 12px;
    font-weight: 500;
    word-break: break-word;
    max-width: 200px;
}

.selectConditionText {
    border: 1px solid #D0D5DD;
    padding: 4px 4px;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    min-height: 40px;
    min-width: 200px;
    color: #8391A2;
    max-width: 200px;
    word-break: break-word;
    margin-top: 10px !important;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: background-color 0.3s ease;
    &:focus {
      outline: 1px solid red !important;
      box-shadow: 0 0 0 3px rgba(139, 92, 246, 0.5) !important;
    }
    .conditionLebel{
        padding-left: 5px;
        font-size: 13px;
    }
    .beforeFonditionLebel{
        padding-left: 5px;
    }
}
.addStyle{
    margin-top: 10px;
    margin-bottom: -5px;
    margin-left: -15px;
    color: #1B4AA2;
    font-weight: bold;
}

.startNodeHandle {
    position: absolute !important;
    bottom: -13px !important;
    min-width: 24px !important;
    min-height: 24px !important;
    cursor: pointer;
    path {
        margin-right: 10px !important;
    }
}

.startNodeWrapper {
    background: white;

    .playrightIcon {
        position: absolute;
        height: 24;
        width: 24;
        top: 14px;
        left: -18px;
        color: #225CCB;
    }

    .label {
        font-size: 18px;
        font-weight: 600;
        color: #101828;
    }

    .subLabel {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 4px;
    }
    .arrowDownwardStyle{
        color: white;
        cursor: pointer;
    }
    .arrowCircleRoundStyle{
        color: #225CCB;
        height: 100%;
        width: 100%;
        cursor: pointed !important;
    }
}

.conditionWrapper {
    .labelStyle{
        font-size: 18px;
        font-weight: 600;
        color: #101828;
    }
    .basicPopoverLeft{
        position: absolute;
          bottom: -15px;
          left: 30px;
          color: #225CCB;
          border-radius: 60px;
          border: 1px solid #EEF2F7;
          background: #FFF;
          padding: 4px 8px;
          font-weight: 600;
          cursor:pointer;
    }

    .basicPopoverRight{
        position: absolute;
          bottom: -15px;
          right: 37px;
          color: #225CCB;
          border-radius: 60px;
          border: 1px solid #EEF2F7;
          background: #FFF;
          padding: 4px 8px;
          font-weight: 600;
          cursor: pointer;
    }
    .buttonStyle{
        font-size: 14px;
        font-weight: 600;
        color: #8391A2;
        padding: 2px 8px;
        border-radius: 60px;
        text-transform: capitalize;

    }
    .paraStyle{
        color: #8391A2;
        font-size: 14px;

    }


}

.actionNodeWrapper{
    .actionLabel{
        font-size: 18px;
        font-weight: 600;
        color: "#101828";
    }
    .actionSublabel{
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 12px
    }
    .typographyStyle{
        border: 1px solid #D0D5DD;
        padding: 8px 14px;
        cursor: pointer;
        border-radius: 8px;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        min-height: 40px;
        min-width: 200px;
        color: #8391A2;
    }
    .handleTopStyle{
        position: absolute;
        top: -12px;
        min-width: 24px;
        min-height: 24px;
        background: #225CCB;
        cursor: pointed !important;
    }
    .handleBottomStyle{
        position: absolute;
        bottom: -12px;
        min-width: 24px;
        min-height: 24px;
        background: #225CCB;
        cursor: pointer;
    }
}