@import '../../../size.mixin.scss';

.tableWrapper {
    box-sizing: border-box;
    max-width: 100%;
    min-height: 100%;
    background: #ffffff;
    border: 0.5px solid #eaeaea;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.07);
    border-radius: 12px;

    @include for-size(md) {
        overflow-x: scroll;
    }

    .loader {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 230px;
    }
    table {
        margin: 10px 30px;
        width: calc(100% - 60px);
        border-collapse: collapse;

        th,
        td {
            padding: 15px 10px;
            min-width: fit-content;
        }

        th {
            font-weight: 500;
            font-size: 13px;
            line-height: 18px;
            color: #8391a2;
            text-align: left;

            img {
                margin-left: 4px;
            }
        }

        tr {
            border-bottom: 1px solid #EFF0F7;
        }

        td {
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #1D2939;

            .avatarStyle{
                width: 38;
                height: 38;
                padding: 13px;
                background: #DDDFE2;

                .firstLetter{
                    color: #1D2939;
                    font-size: 14px;
                    text-transform:capitalize;
                }
                .secondLetter{
                    color: #1D2939;
                    font-size: 14px;
                }
            }
        }

        .flowButtonWrapper {
            display: flex;
            gap: 16px;
            justify-content: flex-start;
            padding-right: 2.5rem;

            button {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 0.5rem;
                color: #364152; //--gray--modern--700
                text-transform: capitalize;
                border: 1px solid #E3E8EF;  //--gray--modern--200
                border-radius: 8px;
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                padding: 6px 1rem;
                background: #fff !important;

                svg{
                    width: 18px;
                    height: 18px;
                    margin-left: 10px;
                }
            }
        }
    }

    .NoData {
        margin: 4rem;
        text-align: center;

        h3 {
            margin-top: 0.5rem;
            color: #344054; //--gray--700
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
        }
    }
}