@import '../../../size.mixin.scss';

.disputeResponseTemplateWrapper {
    padding: 27.63px 22.76px 32.3px 18.24px;

    h4 {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

        color: #225CCB; //--primary--500
    }
}

.orderDeliveredWrapper {
    margin-top: 32.54px;
    display: flex;
    justify-content: space-between;
    gap: 21px;

    @include for-size(sm) {
        flex-direction: column;
    }

    .orderDeliveredCustom {
        width: 33.94%;
        background: #EEF2F7;
        border-radius: 10px;
        padding: 16.38px 11px 15.47px;

        @include for-size(sm) {
            width: 100%;
        }

        .customerResponseWrapper {

            h5 {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;

                color: #225CCB; //--primary--500
                margin-bottom: 11.7px;
            }

            div {
                p {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;

                    color: #344054; //--gray--700
                }
            }
        }
    }

    .processingDocWrapper {
        height: fit-content;
        width: 64.64%;
        background: #EEF2F7;
        border-radius: 10px;
        padding: 18.64px 22px 24px 21px;

        @include for-size(sm) {
            width: 100%;
        }

        .responseDocuments {
            .responseDocument {
                margin-top: 23px;

                p {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;

                    color: #0E2551; //--primary--900

                    img {
                        margin-left: 5px;
                    }
                }

                .responseDocumentField {
                    margin-top: 16.86px;
                    height: 75.39px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    background: #FFFFFF;
                    border: 1px dashed #EAECF0; //--gray--200
                    border-radius: 10px;

                    .fileUploader {
                        display: flex;
                        align-items: center;
                        cursor: pointer;

                        p {
                            font-style: normal;
                            font-weight: 500;
                            font-size: 11.7135px;
                            line-height: 18px;
                            text-transform: capitalize;

                            color: #4E7DD5; //--primary--400
                        }
                    }
                }
            }
        }
    }
}

.orderSubmit {
    margin-top: 23.4px;

    Button {
        text-transform: capitalize;
        border-radius: 8px;

        .submit {
            display: flex;
            gap: 8px;
        }
    }
}