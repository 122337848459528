:root {
  --color-pink: #ee415f;
  --color-green: #58ce97;
  --color-orange: #fc7d4a;
  --color-red: #d14758;
  --color-pale-pink: #ef6e85;
  --color-coral: #f3907e;
  --color-peach: #ecb38d;
  --color-aqua: #a0ded0;
  --color-pale-aqua: #c0ebe1;
  --color-lightest-blue: #f8fcff;
  --color-blue: #4c88e9;
  --color-black: #242e39;
  --color-gray-1: #3b424b;
  --color-gray-2: #68707d;
  --color-gray-3: #949fab;
  --color-gray-4: #c7cdd4;
  --color-gray-5: #edf1f6;
  --color-gray-6: #f7f9fb;
  --color-white: #fff;
}

.fixedScrollbar::-webkit-scrollbar {
  width: 8px;
}

.fixedScrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  background: #ddd;
  border-radius: 6px;
}
.tiptap p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}
.fixedScrollbar::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 6px;
  outline: 1px solid #fff;
}

.ProseMirror::-webkit-scrollbar {
  width: 8px;
}

.ProseMirror * {
  white-space: wrap;
  // white-space: pre-wrap;
  word-wrap: break-word;
}

.ProseMirror::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  background: #ddd;
  border-radius: 6px;
}

.ProseMirror::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 6px;
  outline: 1px solid #fff;
}

.ProseMirror-gapcursor {
  display: none;
  pointer-events: none;
  position: absolute;
  margin: 0;
}
.ProseMirror-gapcursor:after {
  content: '';
  display: block;
  position: absolute;
  top: -2px;
  width: 20px;
  border-top: 1px solid black;
  animation: ProseMirror-cursor-blink 1.1s steps(2, start) infinite;
}
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  color: var(--color-black);
  line-height: 1.5;
  font-family: sans-serif;
  line-height: 1.6;
}

a {
  color: var(--color-blue);
  // text-decoration: underline;
}

p {
  margin: 0;
}

p + p {
  margin-top: 16px;
}

kbd,
code {
  padding: 4px;
  border-radius: 4px;
  background-color: var(--color-gray-6);
  color: var(--color-gray-1);
  box-decoration-break: clone;
  font-size: 14px;
}

.sidebarWrapper {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.04), 0 8px 16px rgba(0, 0, 0, 0.08);
}

.sidebarWrapper::-webkit-scrollbar {
  width: 8px;
}

.sidebarWrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  background: #ddd;
  border-radius: 6px;
}

.sidebarWrapper::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 6px;
  outline: 1px solid #fff;
}

.editor {
  position: relative;
  width: 100%;
  margin-bottom: 17px;
  z-index: 10;
  border-radius: 8px;
  overflow: hidden;
  height: 100%;
}

.menu {
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 8px;
  width: calc(100% - 4px);
  padding: 0px 10px;
  margin: 0;
  border-radius: 4px;
  border-bottom: 1px solid var(--color-gray-5);
  border-left: 1px solid var(--color-gray-5);
  border-right: 1px solid var(--color-gray-5);
  background-color: var(--color-white);
  color: var(--color-gray-2);
  width: 100%;
}

.topMenu {
  position: absolute;
  top: -50px;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 8px;
  width: calc(100% - 4px);
  height: 40px;
  margin: 0;
  margin-top: 10px;
  padding: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 1px solid var(--color-gray-5);
  background-color: var(--color-white);
  color: var(--color-gray-2);
}

.bubble-menu-dark,
.bubble-menu-light {
  display: flex;
  align-items: center;
  gap: 8px;

  padding: 8px;

  border-radius: 4px;
}

.bubble-menu-dark {
  background-color: var(--color-gray-1);
  color: var(--color-white);
}

.bubble-menu-light {
  border: 2px solid var(--color-gray-4);
  background-color: var(--color-white);
  color: var(--color-gray-2);
}

.link-menu {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;

  border: 2px solid var(--color-gray-5);
  border-radius: 4px;
  background-color: var(--color-white);
}

.button,
.button-save,
.button-remove,
.menu-button {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 32px;

  margin: 0;
  padding: 0 8px;

  border: 0;
  border-radius: 4px;
  background: transparent;
  color: currentColor;

  font-family: inherit;
  font-size: inherit;
  line-height: 1;
  font-weight: inherit;

  white-space: nowrap;
  cursor: pointer;
}

.menu-button {
  width: 32px;
  padding: 0;
}

.button:hover,
.button.is-active,
.menu-button:hover,
.menu-button.is-active {
  background-color: var(--color-gray-5);
  color: var(--color-black);
}

.button:disabled,
.menu-button:disabled {
  color: var(--color-gray-4);
}

.button-save {
  background-color: var(--color-green);
  color: var(--color-white);
}

.button-remove {
  background-color: var(--color-red);
  color: var(--color-white);
}

.button-remove:disabled {
  background-color: var(--color-pale-pink);
}

.contentWrapper > :first-child {
  gap: 30px;
  width: 100%;
  max-width: calc(100% - 286px);
  height: 95vh;
}
.contentWrapper {
  .ProseMirror {
    padding-bottom: 20rem;
  }
}

.contentDisputeWrapper {
  height: 50vh;
  width: 100%;

  // div{
  //   width: calc(100% - 10px);
  // }

  .ProseMirror {
    width: 100%;
    height: 315px;
    overflow: auto !important;
    padding: 16px 2rem;
  }
}
.fullWidthDivWrapper {
  width: 100%;
  height: 90vh;
  transition: all 0.5s ease-in-out;

  .ProseMirror {
    width: 100%;
    height: 92vh;
    overflow: auto !important;
    padding: 16px 2rem;
  }
}

.fullContent {
  height: 76vh;
  width: 100%;

  div {
    width: calc(100% - 10px);
  }

  .ProseMirror {
    width: 100%;
    height: 100%;
    overflow: auto !important;
    padding: 16px 2rem;
  }
}

/* Basic editor styles */
.ProseMirror {
  padding: 16px 3rem;
  width: 100%;
  overflow: auto !important;
  height: 98vh;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-left: 4px;
  margin-top: 5px;
}

.editor-mini .ProseMirror {
  padding-top: 8px;
}

.ProseMirror-focused {
  border-color: none;
  outline: none;
}

.ProseMirror > * + * {
  margin-top: 16px;
}

.modal-title {
  margin: 0 0 24px;
  font-size: 20px;
  line-height: 1;
}

.modal-close {
  position: absolute;
  top: 8px;
  right: 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;

  border: 0;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
}

.modal-close:hover {
  background-color: var(--color-gray-5);
  color: var(--color-black);
}

.modal-input {
  width: 100%;
  height: 40px;

  margin-bottom: 16px;

  border: 2px solid var(--color-gray-5);
  border-radius: 4px;

  font-size: inherit;
}

.modal-buttons {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}

.tiptap {
  table {
    border-collapse: collapse;
    margin: 0;
    overflow: hidden;
    table-layout: fixed;
    width: 100%;

    td,
    th {
      border: 2px solid #ced4da;
      box-sizing: border-box;
      min-width: 1em;
      padding: 3px 5px;
      position: relative;
      vertical-align: top;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      background-color: #f1f3f5;
      font-weight: bold;
      text-align: left;
    }

    .selectedCell:after {
      background: rgba(200, 200, 255, 0.4);
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      pointer-events: none;
      position: absolute;
      z-index: 2;
    }
    img {
      height: auto;
      max-width: 100%;

      &.ProseMirror-selectednode {
        outline: 3px solid #68cef8;
      }
    }
    .column-resize-handle {
      background-color: #adf;
      bottom: -2px;
      position: absolute;
      right: -2px;
      pointer-events: none;
      top: 0;
      width: 4px;
    }

    p {
      margin: 0;
    }
  }
}

.tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
