.noCustomerInformation {
    font-size: 12px;
    color: #98A2B3;
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.jiraCommentsWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 10px;
    font-family: 'Inter', sans-serif;

    .orderFulFillmentContainer {
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: space-between;
    }
    .orderFulfillmentTitle {
        color: #1D2939;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px;
    }

    .orderFulfillmentDetails {
        color: #848484;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        margin-top: 0px !important;
        line-height: 18px;
        word-break: break-all;
    }
}