.toolsboxSection {
    width: 286px;
    position: sticky;
    top: 1rem;
    height: fit-content;

    display: flex;
    justify-content: flex-end;


    .toolsbox {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;

        height: 100vh;
        overflow: auto;

        .toolbox {
            width: 7rem;
            gap: 1rem;
            border-radius: 2px;
            border: 1px solid #ccc;
            padding: 1rem;
            text-align: center;
            color: #333;
            cursor: pointer;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .toolIcon {
                width: 1.5rem;
            }

            .toolTitle {
                font-size: 12px;
            }
        }
    }
}

.isExpandStyle {
    list-style: none;
    background: #fff;
    position: absolute;
    top: 55px;
    right: -150px;
    width: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    border-radius: 10px;
    box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.2);
    border: 1px solid #ccc;

    li {
        transition: all 0.4s ease;
        // padding: 4px;
        margin-top: 8px;
        padding: 2px;
    }

    li:hover {
        background-color: rgb(220, 215, 215);
    }
}
.nonExpandStyle{
    display: none;
}
