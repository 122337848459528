@import "../../size.mixin.scss";

.nav {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  margin-bottom: 21.99px;

  @include for-size(xs) {
    gap: 10px;
  }

  h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #101828;
    width: 12%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include for-size(xl){
      width: 28%;
    }

    @include for-size(lg){
      width: 38%;
    }

    @include for-size(ms){
      width: 50%;
    }

    @include for-size(xs){
      width: 100%;
    }
  }

  .navPath {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 18px;

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #8391a2;
      cursor: pointer;
    }

    .active {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #18408e;
      cursor: pointer;
    }
  }
}

.note {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 21.99px;

  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #344054;
  }
}