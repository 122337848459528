.menuTitleStyle{
    border-bottom: "1px solid #ccc";
    padding-bottom:"4px";

}
.titleStyle{
    border-bottom: "1px solid #ccc" !important;
    padding-bottom:"4px";
    text-align: center;
    font-size: 600 !important;

}
.menuTitleStyle:hover{
    background: #ededed;
    cursor: grab;
}
.toolbox {
    width: 7rem;
    gap: 1rem;
    margin: 5px;
    border-radius: 2px;
    border: 1px solid #ccc;
    padding: 1rem;
    text-align: center;
    color: #333;
    cursor: grab;
    user-select: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 115px;
    max-height: 115px;

.toolIcon {
    width: 1.5rem;
    user-select: none;
}

.toolTitle {
    font-size: 12px;
    user-select: none;
}
}