.searchWrapper {
  position: relative;

  .search {
    background: #ffffff;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    width: 100%;
    padding: 10px 14px;
    font-size: 16px;
    line-height: 24px;

    &::placeholder {
      color: #475467;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .searchIcon {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    color: #8391a2;
    cursor: pointer;
    transition: all 0.1s ease-in-out;

    &:hover {
      color: #1b4aa2;
      transform: translateY(-50%) scale(1.5);
    }
  }
}
