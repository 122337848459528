@import "../../size.mixin.scss";

.trial {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 12px 4px 4px;
  gap: 12px;
  width: 100%;
  height: 32px;
  background: #f8f9fb;
  mix-blend-mode: multiply;
  border-radius: 16px;
  margin-bottom: 18px;
  z-index: 1;

  .days {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 10px;
    min-width: 70px;
    height: 24px;
    background: #f79009;
    border-radius: 16px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    white-space: nowrap;
  }

  .time {
    overflow: hidden;
    height: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #18408e;
    flex: 1;
    @include for-size(xs) {
      display: none;
    }
  }

  .upgrade {
    padding: 2px 6px 2px 8px;
    gap: 4px;
    width: fit-content;
    height: 22px;
    background: #225ccb;
    border-radius: 16px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
    white-space: nowrap;
    z-index: 1;
  }
}
