@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import './size.mixin.scss';

* {
  margin: 0;
  border: none;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Inter', sans-serif;
  overflow-x: hidden;
}
.react-pdf__Page__canvas {
  border: 1px solid #000000;
  
}
button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

a {
  text-decoration: none;
}

input,
button {
  font-family: 'Inter', sans-serif;
  outline: none;
}

.pageWrapper {
  display: flex;

  .pageBody {
    width: calc(100% - 246px);

    @include for-size(lg) {
      position: absolute;
      left: 60px;
      top: 0;

      width: calc(100% - 60px);
    }
  }
}

.profit {
  height: 70px;
  width: 100%;
  margin-top: 10px;
  background: #0b9673;
  align-items: center;

  p {
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 24px;
    color: #ffffff;
    padding-top: 15px;
  }
}
.loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  vertical-align: middle;
  flex-direction: column;
}
::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-track {
  background: transparent; 
}
::-webkit-scrollbar-thumb {
  background: #D2D4E2; 
  border-radius:10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #ccc; 
} 