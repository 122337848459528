.selectUpdateWrapper {
  display: block;
  .avatarStyle{
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1D2939;

    span{
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
    }
  }

  .selectStyle{
   border-radius: 8px;
   height: 24px;

    &::before,
    &::after{
      border: none !important;
      content: "" !important;
  }
  }
}