@import '../../../size.mixin.scss';

.Disputes {
    padding: 24px 30px;
    width: 100%;

    .syncingData {
        text-align: center;
        margin: 26px 17.3px 32.42px;

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 11.531px;
            line-height: 17px;
            margin-top: 2px;
        }
    }

    .DisputesSub {
        .Disputesheader {
            display: flex;
            justify-content: space-between;

            .Disputesheading {
                color: #101828; //--gray--900
                font-size: 11.531px;
                font-style: normal;
                font-weight: 500;
                line-height: 17.297px;
            }
        }

        .DisputesFooter {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-top: 36px;
            gap: 11.53px;

            @include for-size(xxs) {
                flex-wrap: wrap;
                justify-content: center;
            }

            .DisputesLeft {
                p {
                    font-size: 25.945px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 100%;
                    letter-spacing: -0.519px;
                }
            }
        }
    }
}