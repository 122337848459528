@import "../../../../size.mixin.scss";
.formWrapper {
  .dropDownWrapper {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 18px;

    .initialSelects {
      // display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      gap: 12px;

      .selectContainer {
        display: flex;
        justify-content: space-between;
        gap: 10px;

        @include for-size(sm) {
          flex-direction: column;
        }

        .initialSelectWrapper {
          display: flex;
          flex-direction: column;
          gap: 6px;
          width: 366px;
          margin-bottom: 15px;

          @include for-size(sm) {
            width: 100%;
          }

          label {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;

            color: #344054;
          }

          .dropDown {
            background: #fff;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            width: 98%;
          }
          .errorStyle {
            color: #dc2626;
            font-size: 13px;
            font-weight: 400;
            margin-top: 0.5rem;
            display: block;
          }
        }
      }

      .textAreaWrapper {
        display: flex;
        flex-direction: column;
        gap: 6px;
        margin-bottom: 15px;

        label {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;

          color: #344054;
        }
      }
      .uploadFile {
        .documentField {
          max-width: 100%;
          cursor: pointer;
        }
      }

      .selectButtonStyle {
        // background-color: #1B4AA2;
        color: #fff;
        text-transform: capitalize;
        border-radius: 8px;
        padding: 8px 14px;
        height: 40px;
        margin-top: 20px;
        &:hover {
          background-color: #1b4aa2 !important;
        }
      }
    }

    .selectWrapper {
      display: flex;
      flex-direction: column;
      gap: 6px;

      label {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;

        color: #344054;
      }

      .dropDown {
        background: #fff;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        max-width: 50%;
      }
    }
  }

  .uploadFile {
    margin: 24px 0;

    label {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;

      color: #344054;
    }

    .documentField {
      margin-top: 6px;
      height: 8rem;
      max-width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      border-radius: 10px;
      border: 1px dashed #d0d5dd;
      background: #fff;

      .fileUploader {
        display: flex;
        align-items: center;
        cursor: pointer;

        p {
          font-style: normal;
          font-weight: 500;
          font-size: 9.71536px;
          line-height: 15px;
          color: #4e7dd5; //--primary--400
          text-transform: capitalize;
        }
      }
    }
  }

  .trackingInfoWrapper {
    margin: 24px 0;

    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;

      color: #344054;
    }

    .trackingInfo {
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-top: 12px;

      .selectWrapper {
        display: flex;
        flex-direction: column;
        gap: 6px;

        label {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;

          color: #344054;
        }

        .dropDown {
          background: #fff;
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          max-width: 50%;
        }
      }

      .inputbox {
        display: flex;
        flex-direction: column;
        gap: 6px;

        label {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;

          color: #344054;
        }

        input {
          max-width: 50%;
          padding: 10px 14px;
          border-radius: 8px;
          border: 1px solid #d0d5dd;
          background: #fff;
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        }
      }
    }
  }

  .messageWrapper {
    margin: 24px 0 32px;
    display: flex;
    flex-direction: column;
    gap: 6px;

    .textField {
      max-width: 50%;

      input {
        min-height: 12.5rem;
      }
    }
  }
}
