.shippingWrapper {
    margin-top: 20px;
    background: #fff;
    border-radius: 8px;
    padding: 30px;
.showingTheTrackingState{
    font-size:12px;
    background: #efefef;
    padding:3px 8px;
    border-radius: 4;
    margin-right: 10px;
}
    .manageHead {
        display: flex;
        gap: 2rem;
        align-items: center;
        justify-content: space-between;

        h5 {
            color: #101828;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
        }
        button {
            border: none;
            outline: none;
            // background-color: #1B4AA2;
            color: #fff;
            border-radius: 8px;
        }

        .headButtons {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-grow: 1;

            button {
                text-transform: capitalize;
                display: flex;
                gap: 8px;
                border-radius: 8px;
            }

            .review {
                border-radius: 8px;
                border: 1px solid #E3E8EF;
                background: #FFF;
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

                p {
                    color: #364152;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                }
            }

            .actionButtons {
                display: flex;
                gap: 12px;

                .send {
                    color: #F4F8FF;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                }

                .queue {
                    border: 1px solid #1B4AA2;
                    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                    color: #1B4AA2;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                }

                .delete {
                    color: #8391A2;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                }
            }
        }
    }

    .responseTable {
        border-radius: 8px;

        table {
            width: 100%;
            border-collapse: collapse;
            border-radius: 8px;

            .dotStyle {
                position: relative;
            }

            .dotStyle:before {
                --size: 3rem;
                content: "";
                top: 0;
                left: 21px;
                position: absolute;
                z-index: 1;
                display: block;
                background: #D5E4FF;
                width: 2px;
                height: 30px;
                margin: 0 auto;
            }

            .dotStyle:after {
                --size: 3rem;
                content: "";
                bottom: 0;
                left: 21px;
                position: absolute;
                z-index: 1;
                display: block;
                background: #D5E4FF;
                width: 2px;
                height: 30px;
                margin: 0 auto;
            }

            td {
                padding: 15px 17px;
                border-bottom: 1px solid #eff0f7;
                min-width: fit-content;
            }

            th {
                padding: 12px 17px;
                font-weight: 500;
                font-size: 13px;
                line-height: 18px;
                color: #8391a2;
                text-align: left;
                border-bottom: 1px solid #eff0f7;
            }

            td {
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                color: #1D2939;
            }

            .status {
                border-radius: 4px;
                background: #EFF0F7;
                padding: 4px 8px;
                text-align: center;
                width: fit-content;

                p {
                    color: #1D2939;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 18px;

                    text-transform: lowercase;
                }
            }

            .eyeTrash {
                display: flex;
                align-items: center;
                gap: 24px;

                img {
                    cursor: pointer;
                }
            }
        }
    }

    .shippingPreview {
        margin-top: 12px;
        border-radius: 8px;
        border: 1px solid #EAEAEA;
        background: #FFF;

        .icon {
            font-size: 18px;
        }

        .estimateDate {
            font-weight: 600;
            font-size: 14px;
            margin: 0;
        }

        // .disputePreviewContent {
        //     overflow-y: scroll;
        //     height: inherit;
        // }
    }
}