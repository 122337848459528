@import '../../size.mixin.scss';

.disputesWrapper {
    background: #e8e8e8;
    width: 100%;
    height: 100%;
    padding: 22.68px 54px 55px 56px;

    @include for-size(lg) {
        padding: 32px 30px;
        gap: 10px;
    }

    @include for-size(xs) {
        padding: 20px;
    }
}