@import "../../size.mixin.scss";

.disputesWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .nav {
        width: 100%;
        display: flex;
        gap: 40px;
        flex-wrap: wrap;
        margin-bottom: 21.99px;

        h3 {
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            color: #101828;
        }

        .navPath {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 18px;

            p {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #8391a2;
                cursor: pointer;
            }

            p:last-child {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #18408e;
            }
        }
    }

    .box {
        width: 100%;
        border-radius: 8px;

        .heading {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            border-bottom: 1px solid #e8e8e8;
            background: white;
            gap: 0.7rem;
            padding: 20px 24px;

            h4 {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: #101828; //--gray--900
                text-transform: capitalize;
                text-align: initial;
            }
        }

        .flowchart {
            width: 100%;
            height: 53rem;
            background-color: #fff;
        }
    }

    .cards {
        margin-top: 2rem;
        width: 100%;
        background-color: #fff;
        padding: 0 3% 1.5rem;

        table {
            width: 100%;

            tr {
                th {
                    text-transform: uppercase;
                    font-weight: 500;
                    color: #8391a2;
                    margin: 0 15px;
                    padding-bottom: 5px;

                    @include for-size(sm) {
                        font-size: 12px;
                        font-weight: 500;
                        gap: 3px;
                    }
                }

                th,
                td {
                    padding: 10px 1.5rem;
                    text-align: start;

                    @include for-size(xs){
                        padding: 10px 5px 10px 18px;
                    }
                }

                td {
                    color: #8794a3;

                    @include for-size(sm) {
                        font-size: 12px;
                        font-weight: 500;
                        gap: 3px;
                    }
                }

                .column1 {
                    width: 25%;
                }
            }

            tbody {
                width: 100%;
                background: #e8e8e8;
                border: 1px solid rgb(147, 147, 244);
                border-radius: 4px;
            }
        }

        .cardsname {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 1.5rem;
            border-bottom: 1px solid #8391a2;

            @include for-size(sm) {
                overflow-x: scroll;
                gap: 0;
                p {
                    font-size: 12px;
                }
            }

            .toggleHead {
                color: #8391a2;
                font-weight: 400;
                padding: 1rem 0.5rem 0.5rem;
                cursor: pointer;
            }

            .underline {
                border-bottom: 1px solid #18408e;
            }
        }
    }
}