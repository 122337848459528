@import '../../size.mixin.scss';

.DisputeDetailsWrapper {
    height: 100%;

    .DisputeDetailsBody {
        width: 100%;
        height: 100%;
        background: #f7f7f7;
        padding: 32px 46px 60px 56px;
        display: flex;
        flex-direction: column;

        @include for-size(lg) {
            padding: 32px 30px 60px;
        }

        .disputeIdWrapper {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin: 9.78px 0 12.51px;

            h3 {
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 32px;
                color: #101828;

                @include for-size(xxs) {
                    font-size: 20px;
                }
            }

            p {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                text-align: center;
                padding: 4px 12px;
                border-radius: 16px;
                text-transform: lowercase;
            }

            p::first-letter {
                text-transform: capitalize;
            }
        }
        .selectButtonStyle{
            background-color: #1B4AA2;
            color: #FFF;
            text-transform: capitalize;
            border-radius: 8px;
            padding: 8px 14px;
            margin-left: 8px;
            height: 40px;
            margin-top: 42px;
            &:hover{
                background-color: #1B4AA2 !important;
             }
        }

        .disputeIdTimeline {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #344054; //--gray--700
            margin-top: -30px;
        }

        .cardsWrapper {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            gap: 13px;
            width: 100%;
            margin: 19.87px 0 25px;

            @include for-size(md) {
                flex-direction: column;
            }

            .main {
                display: flex;
                flex-direction: column;
                width: 74%;
                gap: 14px;

                @include for-size(md) {
                    width: 100%;
                }

                .stageWrapper {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    height: 150px;
                    gap: 12px;

                    .stage_1 {
                        width: 58%;

                        background: #FFFFFF;
                        border: 0.5px solid #EAEAEA;
                        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.07);
                        border-radius: 12px;
                        padding: 21px 17px 25.59px;
                    }

                    .stage_2,
                    .stage_3 {
                        width: 20%;

                        background: #FFFFFF;
                        border: 0.5px solid #EAEAEA;
                        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.07);
                        border-radius: 12px;
                    }
                }

                .dispueByBuyer {
                    width: 100%;

                    background: #FFFFFF;
                    border: 0.5px solid #EAEAEA;
                    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.07);
                    border-radius: 12px;

                }

                .disputeResolver {
                    width: 100%;

                    background: #FFFFFF;
                    border: 0.5px solid #EAEAEA;
                    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.07);
                    border-radius: 12px;
                }

                .disputeResponsePreview {
                    width: 100%;
                    padding: 13px 17px 16px 18px;

                    background: #FFFFFF;
                    border: 0.5px solid #EAEAEA;
                    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.07);
                    border-radius: 12px;
                }

                .disputeReviewWrapper {
                    width: 100%;

                    background: #FFFFFF;
                    border: 0.5px solid #EAEAEA;
                    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.07);
                    border-radius: 12px;
                }
            }

            .sideMain {
                width: 24%;
                display: flex;
                flex-direction: column;
                gap: 12.25px;

                @include for-size(md) {
                    width: 100%;
                    margin-top: 1rem;
                }

                .disputeSummaryWrapper {

                    .disputeSummaryHead {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 9px;

                        .stores {
                            display: flex;
                            padding: 2px 8px;
                            justify-content: center;
                            align-items: center;
                            border-radius: 16px;
                            width: fit-content;
                            background: #F4F8FF;

                            p {
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 18px;
                            }
                        }
                        p {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 20px;
                        }

                        img {
                            width: 73.85px;
                            height: 20px;
                        }

                        .summaryLogo {
                            display: flex;
                            gap: 5px;

                            img {
                                width: fit-content;
                            }

                            p{
                                font-size: 12px;
                            }
                        }
                    }

                    .disputeSummary {
                        width: 100%;
                        padding: 19.15px 13.31px;

                        background: #FFFFFF;
                        border: 0.5px solid #EAEAEA;
                        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.07);
                        border-radius: 12px;
                    }

                }

                .disputeFeatures {
                    width: 100%;

                    border: 0.5px solid #EAEAEA;
                    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.07);
                    border-radius: 12px;
                }
            }
        }
    }
}