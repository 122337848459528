@import '../../../size.mixin.scss';

.disputesWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;


    .loader {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 230px;
        background: #fff;
    }

}