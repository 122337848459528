.formWrapper {
    margin: 2rem 0;

    .initialSelect{
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        flex-direction: column;

        .uploadFile {
            margin: 24px 0;
    
            label {
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
    
                color: #344054;
            }
    
            .documentField {
                margin-top: 6px;
                height: 8rem;
                width: 100%;
                display: flex;
                cursor: pointer;
                justify-content: center;
                align-items: center;
                border-radius: 10px;
                border: 1px dashed #D0D5DD;
                background: #FFF;
    
                .fileUploader {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
    
                    p {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 9.71536px;
                        line-height: 15px;
                        color: #4E7DD5; //--primary--400
                        text-transform: capitalize;
                    }
                }
            }
        }

        .textAreaWrarpper{
            display: flex;
            flex-direction: column;
        }


    }

    .form {
        display: flex;
        flex-direction: column;
        gap: 6px;
        margin: 2rem 0 0;

        label {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;

            color: #344054;
        }

        .textField {
            max-width: 50%;

            input {
                min-height: 12.5rem;
            }
        }
    }

    button {
        margin-top: 1rem;
        text-transform: capitalize;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;

        border-radius: 8px;
        padding: 8px 14px;
        height: fit-content;
    }
}