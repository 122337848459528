@import '../../size.mixin.scss';

.newBillingTableWrapper {
  .cardsWrapper {
    margin: 20px 0;
    display: flex;
    gap: 20px;
    justify-content: space-evenly;

    @include for-size(md) {
      flex-direction: column;
    }

    .card {
      max-width: 100%;
      border-radius: 8px;
      width: 33.3%;
      background: #fff;

      @include for-size(md) {
        width: 100%;
      }
    }
  }
  .checkAllBox {
    display: flex;
    align-items: center;
    input {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      border-radius: 8px;
    }
  }
  button {
    // margin-bottom: 20px;
    text-transform: capitalize;
  }
}
