.disputeWrapper {
    border: 1px solid #EAEAEA;
    border-radius: 8px;
    margin-top: 20px;
    overflow-x: auto;
    .manageHead {
        padding: 21px 17px;
        display: flex;
        justify-content: start;
        align-items: baseline;
        gap: 20px;
        h6 {
            color: #101828;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
        }
    }

    .responseTable {
        // height: 45vh;
        // overflow-y: auto;
        border-radius: 8px;
        table {
            // width: calc(100% - 10px);
            width: 100%;
            border-collapse: collapse;
            border-radius: 8px;

            td {
                padding: 15px 17px;
                border-bottom: 1px solid #eff0f7;
                min-width: fit-content;
            }

            th {
                padding: 12px 17px;
                font-weight: 500;
                font-size: 13px;
                line-height: 18px;
                color: #8391a2;
                text-align: left;
                border-bottom: 1px solid #eff0f7;
            }

            td {
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                color: #1D2939;
            }
            .status {
                border-radius: 4px;
                background: #f0f9ff;
                padding: 4px 8px;
                text-align: center;
                width: fit-content;

                p {
                    color: #1D2939;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 18px;

                    text-transform: lowercase;
                }
            }

            .eyeTrash {
                display: flex;
                align-items: center;
                gap: 24px;

                // img{
                //     cursor: pointer;
                // }
            }
        }
    }
}