.fraudAnalysisWrapper {
    display: flex;
    align-items: center;
    // padding: 10px 20px;
    min-height: 64px;
    background: #fff;

    p {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        margin-left: 5.27px;

        color: #98A2B3;
    }

    .fraudAnalysisDetails {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 9px 0;
        gap: 10px;
        border: 1px solid #F2F4F7;
        ;
        font-family: 'Inter', sans-serif;

        .fraudAnalysisData {
            width: 40%;

            p {
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                color: #101828;
            }

            .fraudAnalysisValue {
                
                p{
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                    margin-top: 6.65px;
                    color: #98A2B3; //--Gray--400
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}