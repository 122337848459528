@import '../../size.mixin.scss';

.disputesWrapper {
    background: #e8e8e8;
    width: 100%;
    min-height: 100vh;
    padding: 22.68px 48px 55px 55px;

    @include for-size(lg) {
        padding: 32px 30px;
        gap: 10px;
    }

    @include for-size(xs) {
        padding: 20px;
    }

    .nav {
        display: flex;
        gap: 40px;
        flex-wrap: wrap;
        margin-bottom: 21.99px;

        @include for-size(xs) {
            gap: 10px;
        }

        h3 {
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            color: #101828;
        }

        .navPath {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;

            p {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #8391a2;
                cursor: pointer;
            }

            p:last-child {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #18408e;
            }
        }
    }

    .status {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        margin: 37.18px 0 22px;

        .statusDiv {
            padding: 12.74px 20.49px;
            background: #ffffff;
            border-radius: 100px;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #101828;
            display: flex;
            align-items: center;
            gap: 4.53px;
            text-transform: capitalize;

            .open {
                width: 7.75px;
                height: 7.75px;
                border-radius: 50%;
            }
        }

        .allDisputes {
            background: #101828;
            border-radius: 100px;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #ffffff;
        }

        .searchDiv {
            margin-left: 35.96px;
            width: 19.93rem;

            @include for-size(mds) {
                margin-left: initial;
            }
        }
    }
}