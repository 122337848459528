.disputeCurrentWrapper {
    padding: 24px 17.3px 28.73px;

    .disputeCurrentData {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 1.5rem;
        padding-bottom: 26.5px;
        border-bottom: 1px solid #EAECF0;

        h6 {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;

            color: #0E2551; //--Primary--900
        }

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;

            text-align: center;
            padding: 4px 12px;
            border-radius: 16px;
            margin-top: 10px;
        }

        .textCapitalize {
            text-transform: lowercase;
        }
        .textCapitalize::first-letter{
            text-transform: capitalize;
        }

        .disputeDueDate {
            background: #F0F9FF; //--blue-light--50
            color: #026AA2; //--blue-light--700
        }
    }

    .disputeCurrentMessageWrapper {
        margin-top: 18.07px;
        padding: 0 18.81px 0;

        .disputeCurrentMessage {
            margin: 1.2rem 0;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 9.5px;

            img {
                margin-top: 5px;
            }

            h6 {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;

                color: #225CCB; //--Primary--500
                text-transform: capitalize;
                margin-bottom: 2.31px;
            }

            p {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;

                color: #344054; //--Gray--700
            }
        }
    }
}