.storeViewWrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .storeDetailsWrapper {
        box-sizing: border-box;
        min-width: 100%;
        min-height: 100%;
        background: #ffffff;
        border: 0.5px solid #eaeaea;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.07);
        border-radius: 12px;
        overflow-x: auto;

        padding: 21px 24px 51px;

        .storeDetailsHead {
            margin-bottom: 1rem;

            h4 {
                color: #101828;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
            }

            .storeDetailsNav {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;

                padding-bottom: 16px;
                border-bottom: 1px solid #F3F3F3;

                .storeNav {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;

                    .stores {
                        display: flex;
                        padding: 2px 8px;
                        justify-content: center;
                        align-items: center;
                        border-radius: 16px;
                        width: fit-content;
                        background: #F4F8FF;

                        p {
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 18px;
                        }
                    }
                }

                button {
                    padding: 8px 14px;
                    border-radius: 8px;
                    border: 1px solid #1B4AA2;
                    background: #1B4AA2;
                    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

                    text-transform: capitalize;
                }
            }
        }

        .storeDetails {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;

            .storeDetail {
                display: flex;
                width: 47%;
                align-items: center;

                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;

                .detailTitle {
                    color: #8391A2;
                    width: 25%;
                }
                .detailData{
                    margin: 0;
                }

            }
        }



        .storeForm {
            h4 {
                color: #101828;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;

                padding-bottom: 1rem;
                border-bottom: 1px solid #F3F3F3;
            }

            .form {
                padding-top: 1rem;

                .manageFormWrapper {
                    .manageFormTabsWrapper {
                        .manageFormTabs {
                            text-transform: capitalize;
                        }
                    }
                }



            }
        }
    }
}