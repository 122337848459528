.flowWrapper{
        width: 100%;
        height: 100%;
      .saveButton {
        padding: 8px 24px;
        gap: 8px;
        background: #1B4AA2;
        border: 1px solid #1B4AA2;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        color: #fff;
        text-transform: capitalize;
        &:hover{
            background-color: #1B4AA2;
        }
    }
      .preview {
        padding: 8px 24px;
        gap: 8px;
        background: white;
        border: 1px solid #1B4AA2;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        color: #1B4AA2;
        text-transform: capitalize;
        border-radius: 8px;
        border: 1px solid  #1B4AA2;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        &:hover{
            background-color: white;
        }
        p{
            display: flex;
            justify-content: center;
            align-items: center;
         span{
            margin-right: 8px;
         }
        }
    }
    .secondPanel {
        margin-top: "100px"
    }
    .exitButton{
        color: #8391A2;
        font-size: 16px;
        font-weight: 500;
        border-radius: 8px;
        border: 1px solid  #E3E8EF;
        background: #FFF;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
    .zoomLabelShowing{
      width: 40px !important;
      user-select: none;
      text-align: center;
      color: #8391A2;
    }
}
