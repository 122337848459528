@import "../../size.mixin.scss";

.addAccountModal {
  padding: 26px 27px;

  .addAccountHeading {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #101828;
  }

  .subHeading {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #8391a2;
    margin-top: 7px;
  }

  .institutions {
    background: #ffffff;
    border: 0.5px solid #eaeaea;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.07);
    border-radius: 12px;
    padding: 32px 23px;
    margin-top: 17px;
  }

  .institution:hover {
    background-color: #f0f3ff;
    cursor: pointer;
  }

  .institutionsTitle {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #98a2b3;
    margin: 22px 0 9px;
  }

  .institutionsList {
    .institution {
      display: flex;
      gap: 12px;
      margin-top: 18px;

      .institutionLogo {
        width: 22px;
        height: 25.96px;
        object-fit: cover;
        margin-top: 5px;
      }

      .lineGroup {
        display: flex;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid #eaecf0;
        padding-bottom: 13.35px;

        .institutionLink {
          font-size: 14px;
          line-height: 20px;
          color: #8391a2;
        }

        .institutionName {
          font-weight: 500;
          font-size: 20px;
          line-height: 18px;
          color: #1d2939;
        }
      }
    }
  }
}

.removeAccountModal {
  padding: 26px 27px;

  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #101828;
    border-bottom: 1px solid #d0d5dd;
    padding-bottom: 12px;
  }

  .info {
    padding: 14.33px 0 12.35px;
    border-bottom: 1px solid #d0d5dd;

    .detailsWrap {
      display: flex;
      gap: 20px;
      margin-top: 15px;

      .detail {
        display: flex;
        gap: 6px;

        .imgWrap {
          width: 20.94px;
          height: 20.94px;
          background: #e9effa;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 10px;
            height: 10px;
            object-fit: cover;
          }
        }

        .name {
          font-weight: 500;
          font-size: 13px;
          line-height: 18px;
          color: #1d2939;
        }

        .email {
          font-weight: 500;
          font-size: 11px;
          line-height: 18px;
          color: #8391a2;
        }

        .id {
          font-weight: 600;
          font-size: 9px;
          line-height: 18px;
          color: #8391a2;
        }
      }
    }
  }

  .confirm {
    margin-top: 20px;
    display: flex;
    gap: 34px;

    p {
      width: 50%;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #344054;
    }

    input {
      background: #ffffff;
      border: 1px solid #d0d5dd;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      padding: 10px 14px;
      width: 50%;

      &::placeholder {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #98a2b3;
      }
    }
  }

  .buttons {
    margin-top: 43.63px;
    display: flex;
    justify-content: space-between;

    button {
      border: 1px solid #d0d5dd;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      padding: 10px 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }

    .cancel {
      background: transparent;
      color: #344054;

      &:hover {
        background: #fafafa;
      }
    }

    .remove {
      background: #f04438;
      color: #ffffff;
      opacity: 0.3;
      cursor: not-allowed;

      &.active {
        cursor: pointer;
        opacity: 1;

        &:hover {
          background: #f04438;
          filter: brightness(0.8);
        }
      }
    }
  }
}

.popmodal {
  @include for-size(xl) {
    width: 80%;
  }

  @include for-size(lg) {
    width: 80%;
  }
}