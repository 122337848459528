@import '../../../size.mixin.scss';

.DisputeSummarySection {

    .handleBySection {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 10.3px;

        .handleByText {
            .handleHeading {
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
            }

            .handleTrackiPal {
                font-size: 12px;
                font-weight: 500;
                line-height: 18px;

                text-align: center;
                border-radius: 14.0861px;
                width: max-content;
                padding: 1.76077px 5.2823px 1.76077px 7.04306px;

                &.handleTrackiPal {
                    background: #ECFDF3; //--Success--50
                    color: #027A48; //--Success--700
                }

                div {
                    display: flex;
                }

                .reason{
                    max-width: 10rem;
                    text-overflow: ellipsis;
                    overflow: hidden;

                    @include for-size(lg) {
                        max-width: 15rem; 
                    }
                }
            }
        }
    }

    .disputeInfoHead {
        margin-bottom: 7.18px;
        p {
            font-weight: 600;
            font-style: normal;
            font-size: 12px;
            line-height: 18px;
        }
    }

    .disputeInfo {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 1rem;
        margin-bottom: 16.95px;

        button{
            background-color: #1B4AA2 !important;
        }


        .disputeData {

            .orderNameContainer{
                display: flex;
                align-items: center;

                span{
                    color: #1B4AA2;
                    cursor: pointer;
                    font-size: 12px;
                    margin-left: 10px;
                    margin-top: 3px;
                    font-weight: bold;
                }
            }

            @include for-size(md) {
                width: 40%;
            }

            @include for-size(xxs) {
                width: 45%;
                text-align: center;
            }

            p {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
            }

            .disputeValue {
                display: inline-block;
                padding: 3px 10px;
                white-space: nowrap;
                border-radius: 16px;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                margin-top: 3.62px;

                &.disputeValue {
                    background: #F4F8FF;
                    color: #18408E;
                }
            }

            .text {
                text-transform: lowercase;
            }

            .text::first-letter {
                text-transform: capitalize;
            }

            .typePayPal {
                margin-top: 3.62px;
                display: flex;
                align-items: center;

                img {
                    width: 27.39px;
                    height: 18.05px;
                }

                p {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;

                    color: #344054; //--gray--700
                }
            }

            .disputePayPalEmail {
                font-style: normal;
                font-weight: 400;
                font-size: 10.7018px;
                line-height: 16px;

                color: #8391A2;
                margin-top: 4.59px;
            }
        }
    }

    .timeEstimate {
        padding-bottom: 16.95px;
        border-bottom: 1px solid #F2F4F7;
    }
}