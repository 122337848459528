.flowWrapper {
    .nav {
        display: flex;
        gap: 40px;
        flex-wrap: wrap;
        margin-bottom: 38px;

        h3 {
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            color: #101828;
        }

        .navPath {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;

            p {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #8391a2;
                cursor: pointer;
            }

            p:last-child {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #18408e;
            }
        }
    }

    h5 {
        color: #101828;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
    }
}