@import '../../../size.mixin.scss';

.disputesWrapper {
    background: #e8e8e8;
    width: 100%;
    height: 100%;
    padding: 22.68px 54px 15px 56px;

    @include for-size(lg) {
        padding: 32px 30px;
        gap: 10px;
    }

    @include for-size(xs) {
        padding: 20px;
    }
}
.nav {
     display: flex;
     gap: 40px;
     flex-wrap: wrap;
     margin-bottom: 21.99px;

     h3 {
         font-weight: 600;
         font-size: 24px;
         line-height: 32px;
         color: #101828;
     }
 }
.saveButtonStyle{
    background-color: #1B4AA2;
     color: "#FFF";
     &:hover{
        background-color: #1B4AA2 !important;
     }
}

 .name {
     width: 100%;
     background-color: #fff;
     border-radius: 4px;
     padding: 19px 32px 30px;
.topNav{
    color: var(--gray-900, #101828);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 18px */
      

}
        .inputbox {
            display: flex;
            flex-direction:column;
            gap: 6px;
            width:31.3%;

            label {
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;

                color: #344054;
            }

            input {
                padding: 6px 12px;
                border-radius: 8px;
                border: 5px solid #D0D5DD;
                background: #FFF;
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            }

            .dropDown {
                background: #FFF;
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            }
                
              
            
        }

        .selectWrapper {
            display: flex;
            flex-direction: column;
            gap: 6px;
            width:31.3%;
         
            

            label {
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;

                color: #344054;
            }

            .dropDown {
                background: #FFF;
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
               
               
                
            }
        }

button {
    padding: 8px 14px;
    gap: 8px;
    background: #1B4AA2;
    border: 1px solid #1B4AA2;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    color: #fff;
    text-transform: capitalize;

    @include for-size(ms) {
       width: 100%;
    }
}
     p {
         font-style: normal;
         font-weight: 500;
         font-size: 14px;
         line-height: 20px;
         color: #344054; //--gray--700
     }

     .input {
         width: 100%;
         display: flex;
         margin-top: 6px;
         gap: 7px;

         @include for-size(ms) {
            flex-direction: column;
         }

         input {
             width: 34rem;
             background: #FFFFFF;
             border: 1px solid #D0D5DD;
             box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
             border-radius: 4px;
             padding: 6px 12px;

             font-style: normal;
             font-weight: 400;
             font-size: 16px;
             line-height: 24px;

             @include for-size(ms) {
                width: 100%;
             }
         }

         button {
             padding: 8px 14px;
             gap: 8px;
             background: #1B4AA2;
             border: 1px solid #1B4AA2;
             box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
             border-radius: 8px;
             color: #fff;
             text-transform: capitalize;
             @include for-size(ms) {
                width: 100%;
             }
         }
     }
 }


 .flowchart {
    margin-top: 16px;
     width: 100%;
     height: calc(100vh - 410px);
    border-radius: 4px;
    background-color: #fff;
 }