.tableWrapper{

    height: 45vh;
    overflow-y: auto;
    border-radius: 12px;
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #eff0f7;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.07);

    table{
        width: 100%;
        border-collapse: collapse;
        border-radius: 8px;

        h3{
            padding: 10px;
        }

        td {
            padding: 15px 17px;
            border-bottom: 1px solid #eff0f7;
            min-width: fit-content;
        }

        th {
            padding: 12px 17px;
            font-weight: 500;
            font-size: 13px;
            line-height: 18px;
            color: #8391a2;
            text-align: left;
            border-bottom: 1px solid #eff0f7;
        }

        td {
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #1D2939;
        }
    }
}