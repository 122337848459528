@import "../../../size.mixin.scss";
.disputesWrapper{
// max-height: 1200px;
  .name {
    width: 100%;
    background-color: #fff;
    border-radius: 12px;
    padding: 19px 32px 30px;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        color: #101828;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
      }

      button {
        padding: 8px 14px;
        text-transform: capitalize;
        border-radius: 8px;
        border: 1px solid #1b4aa2;
        background: #1b4aa2;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      }
    }
    .textAreaWrapper{
      display: flex;
      flex-direction: column;
      width: calc(100% - 33.3%);
textArea{
  overflow-y: auto;
}
      label {
        font-size: 14px;
      }
    }
    .input {
      width: 100%;
      display: flex;
      margin-top: 6px;
      gap: 7px;

      @include for-size(ms) {
         flex-direction: column;
      }

      input {
          width: 34rem;
          background: #FFFFFF;
          border: 1px solid #D0D5DD;
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
          border-radius: 4px;
          padding: 6px 12px;

          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;

          @include for-size(ms) {
             width: 100%;
          }
      }

      button {
          padding: 8px 14px;
          gap: 8px;
          background: #1B4AA2;
          border: 1px solid #1B4AA2;
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
          border-radius: 8px;
          color: #fff;
          text-transform: capitalize;
          @include for-size(ms) {
             width: 100%;
          }
      }
  }
  .inputbox {
    display: flex;
    flex-direction:column;
    gap: 6px;
    width:31.3%;
    z-index: 999;

    label {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;

        color: #344054;
    }

    input {
        padding: 6px 12px;
        border-radius: 8px;
        border: 5px solid #D0D5DD;
        background: #FFF;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }

    .dropDown {
        background: #FFF;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }



}
    .formContent {
      display: flex;
      gap: 12px;
      margin: 1rem 0;
      flex-wrap: wrap;

      .inputbox {
        display: flex;
        flex-direction: column;
        gap: 6px;
        width: 32.5%;

        label {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;

          color: #344054;
        }

        input {
          padding: 10px 14px;
          border-radius: 8px;
          border: 1px solid #d0d5dd;
          background: #fff;
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        }

        .dropDown {
          background: #fff;
          // z-index: 999;
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        }

        p {
          color: #dc2626;
          font-size: 13px;
          font-weight: 400;
        }
      }

      .selectWrapper {
        display: flex;
        flex-direction: column;
        gap: 6px;

        label {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          color: #344054;
        }

        .dropDown {
          background: #fff;
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          max-width: 50%;
        }
      }
    }
  }
}

.templateCreatorWrapper {
  background-color: #fff;
  border-radius: 12px;
  padding: 15px 24px;
  margin-top: 19px;

  .error {
    color: #dc2626;
    font-size: 13px;
    font-weight: 400;
  }
}
