@import '../../size.mixin.scss';

.trialWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .recommended {
    position: absolute;
    top: 9px;
    right: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 8px 2px 6px;
    gap: 4px;
    background: #f4f8ff;
    border-radius: 16px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #18408e;

    p {
      color: #18408e;
    }
  }

  .cardsWrapper {
    display: flex;
    gap: 23px;

    @include for-size(sm) {
      flex-direction: column;
      margin: auto;
    }

    .trialCards {
      box-sizing: border-box;
      position: relative;
      width: 313px;
      padding-bottom: 143px;
      background: #ffffff;
      border: 0.704861px solid #eaecf0;
      box-shadow: 0px 8.45833px 11.2778px -2.81944px rgba(16, 24, 40, 0.08),
        0px 2.81944px 4.22917px -1.40972px rgba(16, 24, 40, 0.03);
      border-radius: 11.2778px;

      .cardTop {
        padding: 35px;
        position: relative;
      }

      h3 {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: #1d2939;
      }

      p {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: flex-end;
        color: #8391a2;
      }

      ul {
        margin: 9px 9px 30px 0;

        li {
          list-style: none;
          margin-top: 9px;
          width: 250px;
          display: flex;
          align-items: flex-start;
          color: #344054;

          img {
            width: 13.33px;
            height: 13.33px;
            margin-top: 3px;
          }

          p {
            color: #344054;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            margin-left: 10px;
          }

          span {
            display: inline-block;
            margin-right: 10px;
            background: rgba(5, 88, 243, 0.4);
            color: #225ccb;
            border-radius: 50%;
          }
        }

        .premium {
          font-weight: 600;
          color: #225ccb;

          span {
            color: #12b76a;
            background: rgb(18, 183, 106, 0.4);
          }
        }
      }

      .buttonWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 30px 22.5556px;
        gap: 24px;
        position: absolute;
        width: 100%;
        height: 143px;
        bottom: 0;
        background: #f2f8ff;

        button {
          box-sizing: border-box;
          width: 268.33px;
          height: 55px;
          background: #225ccb;
          border: 0.704861px solid #225ccb;
          box-shadow: 0px 0.704861px 1.40972px rgba(16, 24, 40, 0.05);
          border-radius: 5.63889px;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
        }
        div {
          width: 267.89px;
          height: 18px;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          text-align: center;
          display: flex;
          justify-content: space-around;
          padding: 0 20px;
          color: #101828;
        }
      }
    }
  }

  .cardsHeading {
    width: 90%;
    margin: auto;

    h3 {
      font-weight: 700;
      font-size: 48px;
      line-height: 42px;
      text-align: center;
      letter-spacing: -0.02em;
      color: #225ccb;

      @include for-size(sm) {
        font-size: 38px;
      }
    }

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 27px;
      margin-top: 17px;
      text-align: center;
      color: #475467;
    }
  }
}