@import '../../size.mixin.scss';

.disputesWrapper {
  background: #e8e8e8;
  width: 100%;
  // min-height: 100vh;

  .cardsWrapper {
    margin: 20px 0;
    display: flex;
    gap: 20px;
    justify-content: space-evenly;

    @include for-size(md) {
      flex-direction: column;
    }

    .card {
      max-width: 100%;
      border-radius: 8px;
      width: 33.3%;
      background: #fff;

      @include for-size(md) {
        width: 100%;
      }
    }
  }

  .date {
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 14px;
    gap: 8px;
    width: 20%;
    margin-top: 8px;
    height: 42px;
    background: #ffffff;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #101828;
    width: 100%;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      color: #364152; //--gray--modern--700
      background-color: #FFF; //--base--white
      padding: 12px 8px;
      right: -45px !important;
    }

    .icon {
      width: 15px;
      height: 15px;
      color: #8391a2;
    }

    .datePicker {
      cursor: pointer;
      width: 100%;
    }
  }
  .searchDiv {
    width: 319px;
    min-width: fit-content;

    @include for-size(md) {
      width: 30%;
    }

    @include for-size(sm) {
      width: 100%;
    }

    .searchInput {
      display: flex;
    }
  }

  .nav {
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
    margin-bottom: 1rem;


    h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #101828;
    }
  }

  .note {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #344054;
    }
  }

  .navPath {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 18px;

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #8391a2;
      cursor: pointer;
    }

    p:last-child {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #18408e;
    }
  }

  .usersSearch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 0 0 16px;
    background: #fff;
    padding: 10px 20px;
    border-radius: 8px;

    .inputSearchContainer{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      gap: 8px;


      @include for-size(sm) {
        flex-direction: column;
      }
    }

    .inputbox {
      display: flex;
      flex-direction: column;
      gap: 6px;
      width: 100%;
      z-index: 999;

      label {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        color: #344054;
      }

      input {
        padding: 6px 12px;
        border-radius: 8px;
        border: 5px solid #D0D5DD;
        background: #FFF;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      }

      .dropDown {
        background: #FFF;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      }  
    }
    // button {
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   gap: 1rem;
    //   color: #364152; //--gray--modern--700
    //   background-color: #FFF; //--base--white
    //   text-transform: capitalize;
    //   padding: 12px 8px;
    // }
  }
}