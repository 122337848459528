@import '../../size.mixin.scss';

.tableWrapper {
    box-sizing: border-box;
    max-width: 100%;
    min-height: 100%;
    background: #ffffff;
    border: 0.5px solid #eaeaea;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.07);
    border-radius: 12px;

    @include for-size(md) {
        overflow-x: scroll;
    }

    .loader {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 230px;
    }

    table {
        margin: 10px 30px;
        width: calc(100% - 60px);
        border-collapse: collapse;

        th,
        td {
            padding: 15px 10px;
            min-width: fit-content;
        }

        th {
            font-weight: 500;
            font-size: 13px;
            line-height: 18px;
            color: #8391a2;
            text-align: left;

            img {
                margin-left: 4px;
            }
        }

        tr {
            border-bottom: 1px solid #EFF0F7;
        }

        td {
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #1D2939;

            .disputeId{
                color: #225CCB;
                font-weight: 500;
                text-decoration: underline;
                cursor: pointer;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .checkedBox input {
                width: 16px;
                height: 16px;
                border: 1px solid #000;
                border-radius: 8px;
            }

            .checkedBoxLabel {
                cursor: pointer;
            }

            .missingInfoContainer {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 300px;
            }
        }
    }

    .NoData {
        margin: 4rem;
        text-align: center;

        h3 {
            margin-top: 0.5rem;
            color: #344054; //--gray--700
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
        }
    }
}