//template-image-handling-scss
.grandParentImageWrapper {
    margin: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .parentImageWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid black;

        img {
            width: 70%;
            margin-top: 10px;
        }

        p {
            text-align: center;
            border-top: 1px solid black;
            width: 100%;
        }
    }
}