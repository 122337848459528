@import '../../size.mixin.scss';

.tableWrapper {
    box-sizing: border-box;
    min-width: 100%;
    min-height: 100%;
    background: #ffffff;
    border: 0.5px solid #eaeaea;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.07);
    border-radius: 12px;
    overflow-x: auto;

    .NoData {
        margin: 2rem auto 5rem;
        text-align: center;

        h3 {
            margin: 2rem;
            color: #8391a2;
        }
    }

    table {
        margin: 10px 30px 0;
        width: calc(100% - 60px);
        border-collapse: collapse;

        th,
        td {
            padding: 15px 10px;
            border-bottom: 1px solid #eff0f7;
            min-width: fit-content;
        }

        th {
            font-weight: 500;
            font-size: 13px;
            line-height: 18px;
            color: #8391a2;
            text-align: left;
        }

        td {
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #1D2939;
        }

        .disputeWrapper {
            .id {
                color: #225CCB; //--primary--500
                font-weight: 500;
                text-decoration: underline;
                cursor: pointer;

                max-width: 5rem;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .accountName {
                text-transform: capitalize;
            }

            .status {
                text-transform: lowercase;
                font-weight: 500;
                text-align: center;
                border-radius: 16px;
                width: max-content;
                padding: 2px 8px;

                max-width: 7rem;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .status:first-letter {
                text-transform: capitalize;
            }
        }
    }
}