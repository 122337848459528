@import "../../../size.mixin.scss";

.disputesWrapper {
    background: #e8e8e8;
    width: 100%;
    min-height: 100vh;
    padding: 22.68px 48px 55px 55px;

    @include for-size(lg) {
      padding: 32px 30px;
      gap: 10px;
    }

    @include for-size(xs) {
      padding: 20px;
    }

    .nav {
        display: flex;
        gap: 40px;
        flex-wrap: wrap;
        margin-bottom: 1rem;

        @include for-size(xs) {
            gap: 10px;
        }

        h3 {
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            color: #101828;
        }
    }

    .note {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #344054;
        }
    }

    .navPath {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 18px;

        p {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #8391a2;
            cursor: pointer;
        }

        p:last-child {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #18408e;
        }
    }
    .usersSearch{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 1rem;
        margin: 33px 0 26px;

        .searchInput{

        }

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            color: #364152; //--gray--modern--700
            background-color: #FFF; //--base--white
            text-transform: capitalize;
            border: 1px solid #E3E8EF;  //--gray--modern--200
            border-radius: 8px;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            padding: 10px 18px;
        }
    }
}