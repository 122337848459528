@import '../../size.mixin.scss';

.newBillingTableWrapper {
  .cardsWrapper {
    margin: 20px 0;
    display: flex;
    gap: 20px;
    justify-content: space-evenly;

    @include for-size(md) {
      flex-direction: column;
    }

    .card {
      max-width: 100%;
      border-radius: 8px;
      width: 33.3%;
      background: #fff;

      @include for-size(md) {
        width: 100%;
      }
    }
  }
  .inputContainer {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;
    margin-bottom: 20px;
    background: #fff;
    padding: 15px 20px;
    border-radius: 8px;
    justify-content: space-between;

    button {
      width: 200px;

      @include for-size(sm) {
        width: 100%;
      }
    }

    .search {
      display: flex;
      gap: 30px;
      width: 100%;


      @include for-size(sm) {
        margin-top: 10px;
       flex-direction: column;
       gap: 10px;
      }
      .searchDiv {
        width: 319px;
        min-width: fit-content;

        @include for-size(md) {
          width: 30%;
        }

        @include for-size(sm) {
          width: 100%;
        }

        .searchInput {
          display: flex;
        }
      }

    .inputBox{
      width: 300px;
      @include for-size(sm) {
        width: 100%;
      }
    }

    }

    @include for-size(sm) {
      flex-direction: column;
    }
  }
  button {
    text-transform: capitalize;
    @include for-size(sm) {
      width: 100%;
    }
  }
}
