@import '../../size.mixin.scss';

.loginPage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .login {
    width: 55%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include for-size(xs) {
      align-items: flex-start;
      width: 100%;
      padding: 20px;
    }

    .footer {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      padding: 0 32px 44px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #8391a2;

      @include for-size(xs) {
        align-self: flex-start;
      }
    }

    .logo {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      padding: 32px 42px 0;
      align-items: center;

      .logoName {
        padding: 10px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h3 {
          font-size: 20px;
          color: #08183a;
        }

        p {
          font-size: 10px;
          color: #35a7ff;
        }
      }
    }
  }

  .loginImage {
    width: 45%;
    height: 100%;

    @include for-size(xs) {
      display: none;
    }

    .gridWrapper {
      display: grid;
      grid-template-rows: repeat(4, 1fr);
      gap: 0;
      padding: 0 39px;
      width: 100%;
      height: 100%;
      max-height: 100vh;
      overflow: hidden;

      .loginGrids {
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        .gridTopFirst {
          border-top-right-radius: 50%;
          background: url(https://source.unsplash.com/random);
          aspect-ratio: 1 / 1;
        }

        .gridTopMiddle {
          border-bottom-left-radius: 50%;
          aspect-ratio: 1 / 1;
          background: #7a9de0;
        }

        .gridTopLast {
          border-top-left-radius: 50%;
          background: url(https://source.unsplash.com/random);
          aspect-ratio: 1 / 1;
        }

        .gridSecondFirst {
          border-radius: 50%;
          background: #a7beea;
          aspect-ratio: 1 / 1;
        }

        .gridSecondMiddle {
          border-top-left-radius: 50%;
          border-bottom-left-radius: 50%;
          background: #4e7dd5;
          aspect-ratio: 1 / 1;
        }

        .gridSecondLast {
          border-top-right-radius: 50%;
          border-bottom-left-radius: 50%;
          background: #a7beea;
          aspect-ratio: 1 / 1;
        }

        .gridBottomFirst {
          border-top-right-radius: 50%;
          border-top-left-radius: 50%;
          background: url(https://source.unsplash.com/random);
          aspect-ratio: 1 / 1;
        }

        .gridBottomMiddle {
          border-radius: 50%;
          background: #a7beea;
          aspect-ratio: 1 / 1;
        }

        .gridBottomLast {
          border-bottom-right-radius: 50%;
          border-top-left-radius: 50%;
          background: #7a9de0;
          aspect-ratio: 1 / 1;
        }
      }

      .loginGridsTwo {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        .gridThirdFirst {
          border-bottom-right-radius: 50%;
          border-bottom-left-radius: 50%;
          background: #18408e;
          aspect-ratio: 1 / 1;
        }

        .gridThirdMiddle {
          background: url(https://source.unsplash.com/random);
          border-bottom-left-radius: 50%;
          aspect-ratio: 1 / 1;
        }

        .gridThirdLast {
          background: url(https://source.unsplash.com/random);
          border-top-right-radius: 50%;
          aspect-ratio: 1 / 1;
        }
      }
    }
  }
}