@import '../../size.mixin.scss';

.disputesWrapper {
  background: #e8e8e8;
  width: 100%;
  min-height: 100vh;
  padding: 22.68px 54px 55px 56px;

  @include for-size(lg) {
    padding: 32px 30px;
    gap: 10px;
  }

  @include for-size(xs) {
    padding: 20px;
  }
  .saveButtonStyle {
    background-color: #1b4aa2 !important;
    text-transform: capitalize !important;
    font-size: 14px !important;
    color: #fff;
    &:hover {
      background-color: #1b4aa2 !important;
    }
  }
  .disputeWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .nav {
      width: 100%;
      display: flex;
      gap: 40px;
      flex-wrap: wrap;
      margin-bottom: 21.99px;

      h3 {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: #101828;
      }

      .navPath {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 18px;

        p {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #8391a2;
          cursor: pointer;
        }

        p:last-child {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #18408e;
        }
      }
    }

    .template {
      box-sizing: border-box;
      min-height: 100%;
      background: #ffffff;
      border: 0.5px solid #eaeaea;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.07);
      border-radius: 12px;

      .box {
        padding: 18px 24px;

        .heading {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          border-bottom: 1px solid #e8e8e8;
          gap: 0.7rem;
          padding-bottom: 20px;

          h4 {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #101828; //--gray--900
            text-transform: capitalize;
            text-align: initial;
          }
        }

        .templatechart {
          padding: 20px 0;
          display: flex;
          flex-direction: column;
          gap: 20px;

          .exit {
            display: flex;
            gap: 8px;
            align-items: center;
            color: #8391a2;
            border: 1px solid #e3e8ef;
            background: #fff;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          }

          .templateDataWrapper {
            .templateData {
              //header level 3, 4
              h3 {
                margin: 2rem 0 5px;
              }

              //paragraph
              p {
                margin: 10px 0;
              }

              //image

              //list
              ul,
              ol {
                padding: 0 24px;
              }

              //table
              table {
                margin: 2rem auto;
                border-collapse: collapse;
                border: 1px solid #8391a2;

                th,
                td {
                  padding: 15px 10px;
                  border: 1px solid #eff0f7;
                  min-width: fit-content;
                  text-align: center;
                }

                th {
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 18px;
                  color: #8391a2;
                  text-align: left;
                }

                td {
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 18px;
                  color: #1d2939;
                }
              }
            }
          }
        }
      }
    }
  }
}
