.disputeWrapper {
    margin-top: 20px;

    button {
        text-transform: capitalize;
        display: flex;
        gap: 8px;
        border-radius: 8px;
        background: #1B4AA2
    }

    .manageHead {
        display: flex;
        gap: 2rem;
        align-items: center;

        h5 {
            color: #101828;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
        }

        .headButtons {
            display: flex;
            align-items: center;
            justify-content: end;
            flex-grow: 1;

            button {
                text-transform: capitalize;
                display: flex;
                gap: 8px;
                border-radius: 8px;
            }

            .review {
                border-radius: 8px;
                border: 1px solid #E3E8EF;
                background: #FFF;
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

                p {
                    color: #364152;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                }
            }

            .actionButtons {
                display: flex;
                gap: 12px;

                .send {
                    color: #F4F8FF;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                }

                .queue {
                    border: 1px solid #1B4AA2;
                    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                    color: #1B4AA2;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                }

                .delete {
                    color: #8391A2;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                }
            }
        }
    }

    .disputePreview {
        margin-top: 12px;
        border-radius: 8px;
        border: 1px solid #EAEAEA;
        background: #FFF;
        height: 321px;

        .disputePreviewContent {
            overflow-y: scroll;
            height: inherit;
        }
    }
}