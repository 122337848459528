.formControl{
  padding-top: 20px !important;
}
.institutionName {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #101828;
  border-bottom: 1px solid #EAEAEA;
  padding:12px 12px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.errorStyle{
  color: #DC2626 !important;
  font-size: 13px;
  font-weight: 400;
  margin-top: 0.5rem;
  display: block;
}
.institutions {
  background: #ffffff;
  border: 0.5px solid #eaeaea;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.07);
  border-radius: 12px;
  margin-top: 12px !important;
}
.calendarStyle{
  margin-top: 35px !important;
}
.calendarStyle > div{
  height: 40px !important;
}
.calendarStyle > div> input{
  padding:7px;
  font-size: 13px;
}
.institutionsForSelect {
  background: #ffffff;
  border-radius: 12px;
  margin-top: 12px !important;
}
.selectionBox{
  background: white;
  overflow-y: auto;

  p{
    color: #101828;
    font-size: 14px;
    font-weight: 500;
  }
  .singleBox{
    cursor: pointer;
  &:hover p{
    color: #225CCB !important;
  }
  &:hover path{
    stroke: #225CCB !important;
  }
  &:hover{
    border-radius: 8px;
    border: 1px solid #225CCB;
  }
  }
}
.selectionBox::-webkit-scrollbar{
  width:8px;
}
.selectionBox::-webkit-scrollbar-track{
  -webkit-box-shadow:inset 0 0 2px rgba(0,0,0,0.3);
  background:rgb(233, 234, 230);
  border-radius:8px;
}
.selectionBox::-webkit-scrollbar-thumb{
  background:#c8c9cc;
  border-radius:8px;
  outline:1px solid #fff;
}
.institutionsList {
  margin-top: 10px;
  overflow-y: auto;

  form{
    // min-height: calc(100vh - 390px);
  }


.institution {
  display: flex;
  gap: 12px;
  .lineGroup {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #eaecf0;
    &:hover {
      background: #F9F9F9;
      border-left: 4px solid #225CCB;
      cursor: pointer;
    }

    .institutionLink {
      font-size: 14px;
      line-height: 20px;
      color: #8391a2;
    }
  }
}
}
.popoverSideBar{
  .mainBox{
    cursor: pointer;
    user-select: none;
    p{
      margin-top: 16px;
    }
  }
  .mainBox{
  &:hover p{
    color: #225CCB !important;
  }
  &:hover path{
    stroke: #225CCB !important;
  }
  }
}
.addAccountModal {
    padding: 26px 27px;
    .institution:hover {
      background-color: #f0f3ff;
      cursor: pointer;
    }

    .institutionsTitle {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #98a2b3;
      margin: 22px 0 9px;
    }
  }

  .ActionOrConditionWrapper::-webkit-scrollbar{
    width:8px;
  }
  .ActionOrConditionWrapper::-webkit-scrollbar-track{
    -webkit-box-shadow:inset 0 0 2px rgba(0,0,0,0.3);
    background:rgb(233, 234, 230);
    border-radius:8px;
  }
  .ActionOrConditionWrapper::-webkit-scrollbar-thumb{
    background:#c8c9cc;
    border-radius:8px;
    outline:1px solid #fff;
  }
  .ActionOrConditionWrapper{
    .sidebarLabel{
      font-size: 18px;
      font-weight: 600;
      line-height: 100%;
      margin-top: 4px;
    }
    .sidebarSubLabel{
      color: #8391A2;
      font-size:14px;
      font-weight: 400;
      margin-top: 2px;
    }
    .sidebarSubLabelBorder{
      border: 1px solid #EAEAEA;
      padding: 0px 10px;
      margin-top: 4px;
    }

    .buttonStyle{
      padding: 8px 24px;
      gap: 8px;
      background: #1B4AA2;
      border: 1px solid #1B4AA2;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      color: #fff;
      text-transform: capitalize;
      &:hover{
          background-color: #1B4AA2;
      }
    }
    .outLineInput {
      width: 100px;
      height: 40px;
      margin-top: 14px;
      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

  }



