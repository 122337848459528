@import '../../../size.mixin.scss';

.filtersDrop {
    display: flex;
    flex-direction: column;

    .filtersDropOptions {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 50px;

        .check {
            display: flex;
            flex-direction: column;

            .filtersDropTitle {
                color: #8391a2;
                margin-bottom: 9px;
            }

            .checkbox {
                display: flex;
                gap: 6px;
                color: #344054;
                margin-bottom: 10px;
                cursor: pointer;

                img {
                    width: 18px;
                    height: 16px;
                }

                input {
                    box-sizing: border-box;
                    width: 16px;
                    height: 16px;
                    outline: none;
                    border: 1px solid #d0d5dd;
                    border-radius: 4px;
                    cursor: pointer;
                }

                div {
                    width: 4.53px;
                    height: 4.53px;
                    border-radius: 50%;
                    align-self: center;
                }

                label {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    color: #344054; //--Gray--700
                    text-transform: lowercase;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                label::first-letter {
                    text-transform: capitalize;
                }
            }
        }
    }
}

.searchButtons {
    display: flex;
    justify-content: flex-end;
    gap: 10.84px;

    button {
        padding: 8px 14px;
        gap: 8px;
        height: 36px;
        background: #ffffff;
        border: 1px solid #d0d5dd;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #344054;
    }

    .buttonApply,
    .buttonReset {
        display: flex;
        align-items: center;
        gap: 11.33px;
        text-transform: capitalize;
    }

    .buttonApply {
        background: #1b4aa2;
        color: #ffffff;
    }
}