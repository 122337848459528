@import "../../../../size.mixin.scss";

.search {
    box-sizing: border-box;
    width: 100%;
    background: #ffffff;
    border: 0.5px solid #eaeaea;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.07);
    border-radius: 12px;
    margin: 24px 0;
    padding: 0 32px;

    .searchTop {
        width: 100%;
        padding: 29.02px 0 31.31px;
        background: #ffffff;
        display: flex;
        align-items: center;
        gap: 20.58px;

        @include for-size(xs) {
            flex-wrap: wrap;
            gap: 10px;
        }

        @include for-size(md) {
            gap: 17px;
        }

        @include for-size(sm) {
            flex-direction: column;
        }

        .searchDiv {
            width: 600px;
            min-width: fit-content;

            @include for-size(md) {
                width: 30%;
            }

            @include for-size(sm) {
                width: 100%;
            }

            .searchInput {
                display: flex;
            }
        }

        .createNew{
            width: 100%;
            display: flex;
            justify-content: end;

            .git {
                // gap: 8px;
                background: #1B4AA2;
                border-radius: 8px;
                padding: 8px 14px;
                text-transform: capitalize;
                box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

                p {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    text-transform: capitalize;
                }
            }
        }

        .filterButton {
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 16px;
            gap: 8px;
            width: 114.33px;
            background: #1b4aa2;
            border: 1px solid #1b4aa2;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: 8px;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #ffffff;

            @include for-size(md) {
                width: 30%;
                gap: 10px;
            }

            @include for-size(sm) {
                width: 100%;
                gap: 10px;
            }

            @include for-size(xs) {
                flex-wrap: wrap;
            }

            .icon {
                width: 19.44px;
                height: 17.5px;

                @include for-size(md) {
                    width: 16px;
                }
            }
        }
    }

    .filters {
        position: relative;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #344054;
        padding-bottom: 46px;
    }
}


//scss for ApplyFilter
.filtersDrop {
    display: flex;
    flex-direction: column;

    .filtersDropOptions {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 50px;

        .check {
            // display: flex;
            // flex-direction: column;
            width: 100%;

            .filtersDropTitle {
                color: #8391a2;
                margin-bottom: 9px;
            }

            .filterOptions{
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                height: 119px;

                @include for-size(sm){
                    height: auto;
                }

                .checkbox {
                    display: flex;
                    gap: 6px;
                    color: #344054;
                    margin-bottom: 10px;

                    img {
                        width: 18px;
                        height: 16px;
                    }

                    input {
                        box-sizing: border-box;
                        width: 16px;
                        height: 16px;
                        outline: none;
                        border: 1px solid #d0d5dd;
                        border-radius: 4px;
                        cursor: pointer;
                    }

                    div {
                        width: 4.53px;
                        height: 4.53px;
                        border-radius: 50%;
                        align-self: center;
                    }

                    label {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        color: #344054; //--Gray--700
                        text-transform: lowercase;

                        // max-width: 9rem;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }

                    label::first-letter {
                        text-transform: capitalize;
                    }
                }

            }

        }
    }
}
.searchButtons {
    display: flex;
    justify-content: flex-end;
    gap: 10.84px;

    button {
        padding: 8px 14px;
        gap: 8px;
        height: 36px;
        background: #ffffff;
        border: 1px solid #d0d5dd;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #344054;
    }

    .buttonApply,
    .buttonReset {
        display: flex;
        align-items: center;
        gap: 11.33px;
        text-transform: capitalize;
    }

    .buttonApply {
        background: #1b4aa2;
        color: #ffffff;
    }
}