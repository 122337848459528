@import "../../../size.mixin.scss";

.notificationsWrapper {
  padding: 37.27px 32.76px 31.91px 38.77px;

  .heading {
    margin-bottom: 4px;

    h3 {
      color: #101828;

      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
    }

    .intro {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #8391a2;
    }
  }

  .infoDiv {
    .info:not(:last-child) {
      border-bottom: 1px solid #EAECF0;
    }

    .info {
      padding: 24px 0;

      .notify {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 61px;
        width: 78%;

        @include for-size(ms) {
          flex-direction: column;
          gap: 1rem;
        }

        .desc,
        .infoSwitches {
          display: flex;
          flex-direction: column;
        }

        .desc {
          h3 {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #344054;
          }

          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #8391a2;
          }
        }

        .infoSwitches {
          gap: 16px;

          .infoSwitch {
            display: flex;
            gap: 8px;

            p {
              color: #344054;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}